/* You can add global styles to this file, and also import other style files */
.image-box {
    position: relative;
    top: 0;
    left: 0;
}

.captured {
    position: relative;
    top: 0;
    left: 0;
}

.overlay {
    position: absolute;
    left: 0px;
}

button {
    margin-bottom: 8px;
    padding: 4px;
}

#video-record-icon {
    position: absolute;
}

#coordinator-network-quality {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 12%;
}

#inspector-network-quality {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 6%;
}

.tertiary-pane #chat-log {
    height: 512px;
}

.secondary-pane #chat-log {
    height: 148px;
}

.inspector-session #image-container {
    flex-direction: column;
    overflow-y: scroll;
    max-height: 352px;
}

.secondary-pane .warn {
    background-color: coral;
}
.secondary-pane .coordinates {
    background-color: lightgrey;
}

#need-help {
    margin-bottom: 4px;
}

.form-field {
    width: 24rem;
    display: flex;
    flex-direction: row;
    margin: 1.5rem 0 1rem 0;
  }
  
  .required-asteric {
    color: red;
  }
  
  :host ::ng-deep .p-inputtext {
    width: 100%;
  }
  
  p-inputmask {
    width: 100%;
  }